<script>
    /***
     Name: list
     Code: sa0ChunLuyu
     Time: 2021/11/15 19:26
     Remark: 列表
     */

    export default {
        data() {
            return {
                list: []
            }
        },
        mounted() {
            this.Get_spec_group()
        },
        methods: {
            formClick(e) {
                let id = e[0] ? e[0] : this.$route.params.id
                if (Number(id) !== Number(this.$route.params.id)) {
                    this.$router.push({
                        params: {
                            id: id
                        }
                    })
                }
            },
            Get_spec_group() {
                this.$sa0.post({
                    url: this.$api('Get_spec_group'),
                    data: {
                        danda_type: 3,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.list = response.data.list
                            let id = Number(this.$route.params.id);
                            if (id === 0 && this.list.length !== 0) {
                                this.formClick([this.list[0].group_id])
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            }
        }
    }
</script>
<template>
    <div class="ppage_left_wrapper">
        <div class="task_tpl_title_wrapper">
            <div class="ml-3">样品组列表</div>
        </div>
        <div class="task_tpl_tree_wrapper ml-3">
            <a-tree :tree-data="list" :replaceFields="{title:'name', key:'group_id' }" :show-line="true" @select="formClick">
                <a-icon slot="icon" type="carry-out"/>
            </a-tree>
        </div>
    </div>
</template>
<style scoped>
    .ppage_left_wrapper{
        margin-left: 20px;
        border: 1px solid #5a5e6620;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 250px;
        }

    .task_tpl_title_wrapper{
        height: 40px;
        line-height: 40px;
        background: #eeeeee;
        }

    .task_tpl_bottom_wrapper:hover{
        font-weight: bold;
        }

    .task_tpl_bottom_wrapper{
        cursor: pointer;
        position: absolute;
        bottom: 0;
        height: 40px;
        left: 0;
        right: 0;
        line-height: 40px;
        background: #eeeeee;
        }

    .task_tpl_tree_wrapper{
        position: absolute;
        top: 40px;
        bottom: 10px;
        left: 0;
        right: 0;
        overflow-y: auto;
        padding-bottom: 20px;
        }

</style>
