<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close'],
        data() {
            return {
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {},
                types: [],
                get_perser: [],
            }
        },
        mounted() {
            this.Sampletypelist()



        },
        methods: {
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            // 存放位置
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 3
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.types = response.data.list
                            let num01 = {
                                id: 0,
                                name: ''
                            }
                            this.types.unshift(num01)
                            console.log('---------', this.types)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


        }
    }
</script>
<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="请填写采集信息" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="编号">
                        <a-input v-model="form.in_number" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="经纬度">
                        <a-input v-model="form.jing_dot" placeholder="经度" class="w100"/>
                        <a-input v-model="form.wei_dot" placeholder="纬度" class="w100 ml20"/>
                    </a-form-model-item>
                    <a-form-model-item label="采集地点">
                        <a-input v-model="form.pick_space" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="采集时间">
                        <a-date-picker v-model="form.pick_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="样品存放位置">
                        <a-select v-model="form.save_id" placeholder="请选择" class="w300">
                            <a-select-option v-for="(item,key ) in types" :key="key" :value="item.id">
                                {{ item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                        <a-button type="primary">
                            确定
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    .w100{ width: 100px}

    .ml20{ margin-left: 20px}

    .w300{ width: 250px}

</style>
