<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="sidebar bgc"><List ref="list"></List></div>
            <div class="wrap-container"><Item :reloadList="reloadList"></Item></div>
        </div>
    </div>
</template>
<script>
    import List from "../caijiguanli/list/list";
    import Item from "../caijiguanli/item/item";
    export default {
        name: "caijiguanli",
        components: {List, Item},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            reloadList() {
                this.$refs.list.getGroupList()
            }
        }
    }
</script>

<style scoped>
    .wraper{ width: 100%; height: 100%; display: flex;}
    .wrap-mainer{ flex: 1;display: flex; }
    .sidebar{ width: 250px;  display: flex; flex-direction: column}
    .wrap-container{ flex:1; overflow-y: auto}
</style>
