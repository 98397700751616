<script>
    /***
     Name: create/create
     Code: sa0ChunLuyu
     Time: 2021/11/17 09:42
     Remark: 创建
     */
    import BeforeC from '../before/item.vue'
    import Startcaiji from '../caijibtn/startcaiji.vue';
    import Okcaiji from '../caijibtn/okcaiji.vue';
    export default {
        props: ['reloadList'],
        components: {BeforeC,Startcaiji,Okcaiji},
        data() {
            return {
                drawer_visible: false,
                drawer_visible1: false,
                page: 1,
                count: 0,
                pagesize: 0,
                name: '',
                group_id: 0,
                group_number: '',
                before_show: false,
                visible: false,
                get_number: '',
                user_list: [],
                save_list: [],
                type_list: [],
                num_type: 1,
                link_draft: 0,
                yp_list: [],
                tableData: [],
                get_perser: [],
                types: [],
            }
        },
        watch: {
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.mountedDo()
                },
                deep: true
            },
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.mountedDo();
        },
        methods: {
            befName() {
                if ((typeof this.$refs.bef) === 'undefined') {
                    return '';
                } else {
                    return this.$refs.bef.create_data.name
                }
            },
            befId() {
                if ((typeof this.$refs.bef) === 'undefined') {
                    return 0;
                } else {
                    return this.$refs.bef.create_data.id
                }
            },
            mountedDo() {
                this.group_id = this.$route.params.id
                this.getInfo();

            },
            getInfo() {
                this.$sa0.post({
                    url: this.$api('Getspecitembyid'),
                    data: {
                        page: this.page,
                        group_id: this.$route.params.id,
                        danda_type: 3
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.name = response.data.result.info.name;
                            this.group_number = response.data.result.info.group_number;
                            this.link_draft = response.data.result.info.link_draft;
                            // console.log(this.link_draft)
                            this.tableData = response.data.result.list;
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page
                    }
                })
            },


            saveClick(item) {
                if (item.status === 3) {
                    if(item.get_pers.length === 0){
                        return layer.msg('请选择接收人')
                    }
                    if(item.pick_time === ''){
                        return layer.msg('请选择采集时间')
                    }
                    if(item.pick_space === ''){
                        return layer.msg('请填写采集地点')
                    }
                }
                let data = {
                    id: item.id,
                    status: item.status,
                    get_per: item.get_pers[item.get_pers.length - 1],
                    get_pers: JSON.stringify(item.get_pers),
                    pick_time: item.pick_time,
                    save_id: item.save_id,
                    pick_space: item.pick_space,
                }
                // console.log(data)
                console.log(JSON.stringify(data))
                // return
                this.$sa0.post({
                    url: this.$api('Set_spec_item'),
                    data: data
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.getInfo()
                            console.log(JSON.stringify(data))
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 类型转换
            wordType(status) {
                let type_arr = ['采集待处理', '采集中', '采集完成', '接收完成']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },

            onClose() {
                this.visible = false
            },
            handleChange(value) {
                console.log(`selected ${value}`);
            },

            openBefore() {
                this.$refs.bef.visible = true
                let id = this.link_draft

                this.link_draft = id;
                this.$refs.bef.reloadInfo(id)
            },

            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            },

            // 定义 关闭抽屉时的 函数
            onCloseDrawer1() {
                this.drawer_visible1 = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer1() {
                this.drawer_visible1 = true;
            }
        }
    }
</script>
<template>
    <div>
        <BeforeC :bid="link_draft" ref="bef"></BeforeC>
        <div class="group_info_wrapper">
            <a-space>
                <div class="info_wrapper">
                    <div class="my-3">
                        <a-space>
                            <div class="input_title_wrapper text-right"><b>样品组名称：</b></div>
                            <div>
                                <a-input v-model="name" class="input_wrapper" placeholder="请输入样品组名称"></a-input>
                            </div>
                        </a-space>
                    </div>
                    <div class="my-3">
                        <a-space>
                            <div class="input_title_wrapper text-right"><b>样品组编号：</b></div>
                            <div>
                                <a-input v-model="group_number" class="input_wrapper" placeholder="请输入样品组编号"></a-input>
                            </div>
                        </a-space>
                    </div>
                    <div class="my-3 before_wrapper">
                        <div @click="openBefore()" class="before_cover_wrapper"></div>
                        <a-space>
                            <div class="input_title_wrapper text-right"><b>预委托单信息：</b></div>
                            <div>
                                <a-input :value="befName()" class="input_wrapper" placeholder="请输入预委托单信息"></a-input>
                            </div>
                        </a-space>
                    </div>
                </div>
                <div class="line_wrapper">
                    <div class="code_wrapper"></div>
                    <a-button class="w-full mt-3" type="primary" size="small">选择其他样品组条码样式</a-button>
                    <a-button class="w-full mt-3" type="primary">打印样品组条码</a-button>
                </div>
            </a-space>
        </div>
        <div class="list_wrapper mt-5">
            <div class="list_button_wrapper">
                <a-space align="end">
                    <a-button type="primary" size="small">打印选中样品条码</a-button>
                    <div class="list_code_wrapper"></div>
                    <a-button type="primary" size="small">选择其他样品条码样式</a-button>
                </a-space>
            </div>
            <div>
                <table class="layui-table">
                    <thead>
                    <tr>
                        <th>样品类型</th>
                        <th>样品名称</th>
                        <th>样品编号</th>
                        <th>采集地点</th>
                        <th>经纬度</th>
                        <th>采集时间</th>
                        <th>采集人</th>
                        <th>接收人</th>
                        <th>样品状态</th>
                        <th>样品存放位置</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="( item,key) in tableData" :key="key">
                        <td>{{item.type_name}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.in_number}}</td>
                        <td>
                            {{item.pick_space}}
                        </td>
                        <td>
                            <a-space>
                                经度:{{item.jing_dot}}<br>
                                纬度:{{item.wei_dot}}
                            </a-space>
                        </td>
                        <td>
                            {{item.pick_time}}
                        </td>
                        <td>{{item.pick_per_name}}</td>
                        <td>
                            {{item.get_per_name}}
                        </td>
                        <td>
                            {{wordType(item.status)}}
                        </td>
                        <td>
                            {{item.save_name}}
                        </td>
                        <td style="width: 250px">
                            <a-button type="primary" @click="showDrawer">开始采集</a-button>
                            <a-button type="primary"  style="margin-left: 10px" @click="showDrawer1">采集完成</a-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
            </div>
        </div>

        <div>

        </div>

<!--开始采集弹窗-->

        <Startcaiji :close="onCloseDrawer" :visible="drawer_visible"></Startcaiji>

<!--        采集完成-->
        <Okcaiji :close="onCloseDrawer1" :visible="drawer_visible1"></Okcaiji>

    </div>
</template>
<style scoped>
    .before_wrapper{
        position: relative;
        }

    .before_cover_wrapper{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        }

    .list_code_wrapper{
        width: 200px;
        height: 100px;
        background: #00000040;
        }

    .code_wrapper{
        width: 300px;
        height: 100px;
        background: #00000040;
        }

    .line_wrapper{
        margin-left: 50px;
        }

    .input_title_wrapper{
        width: 180px;
        }

    .input_wrapper{
        width: 300px;
        }
</style>
